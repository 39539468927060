<template>
  <div class="group-hover:scale-110 transition-transform">
    <div v-if="subscribedUntil" class="flex items-center space-x-1 flex-nowrap">
      <CreditCardIcon class="size-4 text-green-800" />
      <span class="text-xs">{{ subscribedUntil }}</span>
    </div>
    <div v-else-if="timeLeft" class="flex items-center space-x-1 flex-nowrap">
      <ClockIcon class="size-4 text-green-800" />
      <span class="text-xs">{{ timeLeft }}</span>
    </div>
    <LockClosedIcon v-else class="size-4 text-red-800" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { LockClosedIcon, ClockIcon, CreditCardIcon } from '@heroicons/vue/24/outline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const props = defineProps({
  row: {
    type: Object,
    default: () => {}
  }
});

const subscribedUntil = computed(() => {
  if (!props.row.subscriptionCurrentPeriodStart || !props.row.subscriptionCurrentPeriodEnd) {
    return null;
  }
  if (dayjs(props.row.subscriptionCurrentPeriodStart).isAfter(dayjs())) {
    return null;
  }
  if (dayjs(props.row.subscriptionCurrentPeriodEnd).isBefore(dayjs())) {
    return null;
  }
  // time left human readable 1 day or 2 hours or 3 minutes
  return dayjs(props.row.subscriptionCurrentPeriodEnd).fromNow(true);
});

const timeLeft = computed(() => {
  if ((props.row.timeLeftSeconds ?? null) === null) {
    return null;
  }
  const _duration = dayjs.duration(props.row.timeLeftSeconds, 'seconds');
  const hours = Math.floor(_duration.asHours());

  return `${hours}:${_duration.format('mm:ss')}`;
});
</script>
