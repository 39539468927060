<template>
  <page-header title="Program" :identifier="programId">
    <router-link
      v-if="isAdmin()"
      :to="`/program/${programId}`"
      class="btn-outline-primary ml-auto h-10 mr-2"
    >
      <PencilSquareIcon class="size-5 -ml-1 mr-2" />
      Go to Edit
    </router-link>
    <template v-if="isLoaded">
      <button class="btn-primary ml-auto h-10" :class="{'disabled': isSaving || !isDataChanged}" @click="save">
        <CircleStackIcon class="size-5 -ml-1 mr-2" />
        Save
      </button>
    </template>
  </page-header>

  <template v-if="isLoaded">
    <program-icon :row="program" class="pointer-events-none scale-125 origin-bottom-left" />
    <seo-form ref="seoMetaFormRef" :seo-meta="seoMeta" @save="save" />
  </template>

  <div v-else>
    Loading...
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount, toRaw } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import PageHeader from '../components/PageHeader.vue';
import ProgramIcon from '../components/ProgramIcon.vue';
import SeoForm from '../components/SeoForm.vue';
import {
  CircleStackIcon,
  PencilSquareIcon
} from '@heroicons/vue/24/outline';
import { useFetch } from '@vueuse/core';
import { apiDomain } from '../composables/useConstants';
import { Modal } from '../composables/useModal';
import { useToast } from 'vue-toastification';
import hashSum from 'hash-sum';
import { isAdmin } from '../composables/useAuth';

const props = defineProps({
  programId: {
    type: String,
    default: null
  }
});

const toast = useToast();
const seoMetaFormRef = ref(null);
const isLoaded = ref(false);
const isSaving = ref(false);
const program = ref(null);
const seoMeta = ref({});
const seoMetaHashSum = ref(null);

const isDataChanged = computed(() => seoMetaHashSum.value !== hashSum(seoMeta.value));

if (props.programId) {
  load();
} else {
  isLoaded.value = true;
}

async function load() {
  const { data } = await useFetch(`${apiDomain}/api/program/${props.programId}`).get().json();
  program.value = data.value;
  seoMetaHashSum.value = hashSum(data.value?.seoMeta);
  seoMeta.value = data.value?.seoMeta;
  isLoaded.value = true;
}

async function save() {
  isSaving.value = true;

  seoMetaFormRef.value.onValidate();
  if (seoMetaFormRef.value.getResult().$invalid) {
    return;
  }

  const { data, error } = await useFetch(`${apiDomain}/api/program/seo/${props.programId}`).post({ seoMeta: toRaw(seoMeta.value) }).json();

  isSaving.value = false;

  if (error.value) {
    toast.error(error.value);
  }

  if (data.value) {
    program.value = data.value;
    seoMetaHashSum.value = hashSum(data.value?.seoMeta);
    seoMeta.value = data.value?.seoMeta;
    toast.success('Program saved successfully');
  }
}

function handleBeforeUnload(e) {
  if (!isDataChanged.value || !Object.keys(seoMeta.value).length) {
    return;
  }
  e.preventDefault();
  e.returnValue = '';
}

onBeforeRouteLeave(async (to, from, next) => {
  if (!isDataChanged.value || !Object.keys(seoMeta.value).length) {
    return next();
  }

  const confirm = await Modal.confirm({
    title: 'Unsaved changes',
    body: `You have unsaved changes. <br/> If you leave this page, your changes will be lost. <br/> Are you sure you want to exit without saving?`,
    okButton: 'Leave',
    iconColor: 'primary'
  });

  return confirm ? next() : next(false);
});

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>
