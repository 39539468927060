<template>
  <form class="max-w-5xl">
    <div class="mt-10 mb-40 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <form-input v-model="user.name" label="Name" class="md:col-span-4" :error-msg="result.name.$messages.join(' | ')" />

      <form-input v-model="user.email" label="Email" class="md:col-span-4" :error-msg="result.email.$messages.join(' | ')" />

      <div class="col-span-2" />

      <form-image-upload v-model="user.avatar" label="Avatar" class="md:col-span-2" />

      <form-select v-model="user.tier" label="Tier" class="md:col-span-2" :items="tiers" />
    </div>
  </form>
</template>

<script setup>
import { reactive } from 'vue';
import FormInput from './FormInput.vue';
import FormImageUpload from './FormImageUpload.vue';
import FormSelect from './FormSelect.vue';
import useValidate from 'vue-tiny-validate';

const user = defineModel('user', {
  type: Object,
  default: () => ({
    name: '',
    email: '',
    deletedAt: null,
    canRunPrograms: false,
    avatar: ''
  })
});

defineExpose({
  onValidate,
  getResult
});

const tiers = [
  { id: 'user', name: 'User' },
  { id: 'admin', name: 'Admin' },
  { id: 'seo', name: 'SEO' }
];

const rgxCheckEmail = rgx => value => rgx.test(value);

const rules = reactive({
  name: {
    name: 'required',
    test: value => Boolean(value),
    message: 'Name must not be empty.'
  },
  email: [
    {
      name: 'required',
      test: value => Boolean(value),
      message: 'Email must not be empty.'
    },
    {
      name: 'required',
      test: rgxCheckEmail(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
      message: 'Email must be valid.'
    }
  ]
});
const options = reactive({
  autoTest: true
});
const { result } = useValidate(user, rules, options);

async function onValidate() {
  return await result.value.$test();
}

function getResult() {
  return result.value;
}
</script>

<style scoped>
input[type="checkbox"]:focus {
  outline: none;
  box-shadow: none;
}
</style>
