<template>
  <page-header title="Programs">
    <router-link
      to="/program"
      class="btn-primary ml-auto h-10"
    >
      <PlusCircleIcon class="size-5 -ml-1 mr-2" />
      Add
    </router-link>
  </page-header>

  <programs-empty v-if="isEmpty && search === null" />

  <template v-else>
    <div class="flex justify-between mb-4">
      <input-search v-model="search" class="max-w-lg grow" />

      <reload-button class="ml-4" @click="reloadGrid" />
    </div>

    <grid
      ref="gridRef"
      :data-handler="getPrograms"
      :columns="columns"
      :row-class="rowClass"
      :per-page="15"
      @delete="onDelete"
      @show-program="onShowProgram"
      @hide-program="onHideProgram"
      @enable="onEnable"
      @disable="onDisable"
    />
  </template>
</template>

<script setup>
import { ref, markRaw, computed } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import ReloadButton from '../components/ReloadButton.vue';
import InputSearch from '../components/InputSearch.vue';
import ProgramsEmpty from '../components/ProgramsEmpty.vue';
import ProgramListActions from '../components/ProgramListActions.vue';
import ProgramIcon from '../components/ProgramIcon.vue';
import Grid from '../components/grid/Grid.vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { useFetch, watchDebounced, useUrlSearchParams } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { apiDomain } from '../composables/useConstants';
import { Modal } from '../composables/useModal';
import { useToast } from 'vue-toastification';
import { isAdmin } from '../composables/useAuth';

const gridRef = ref(null);
// const search = ref(null);
const isEmpty = ref(false);
const router = useRouter();
const toast = useToast();

function rowClass(_row, index) {
  return (index % 2 ? 'bg-zinc-300/10' : '') + (_row.deletedAt || !_row.isEnabled ? ' [&>td:not(:last-child)]:opacity-30' : ''); // stripes class for row
}

function reloadGrid(updateQuery = {}) {
  if (gridRef.value?.reload) {
    gridRef.value.reload(updateQuery);
  }
}

const params = useUrlSearchParams('history', { initialValue: { search: null } });

const search = computed({
  get: () => params.search,
  set: value => params.search = value
});

watchDebounced(
  search,
  () => {
    reloadGrid({ offset: 0 });
  },
  { debounce: 500, maxWait: 1000 }
);

function getUrl({ limit, offset, sort, direction }) {
  const data = { limit, offset, order: sort, direction: direction?.toLowerCase(), search: search.value || '' };

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/program/?${searchParams}`;
}

async function getPrograms(args) {
  const { limit, offset, sort, direction } = args;
  const { data } = await useFetch(getUrl({ limit, offset, sort, direction })).get().json();

  isEmpty.value = false;

  return {
    total: data.value.total,
    rows: data.value.programs
  };
}

const columns = [
  {
    title: '#',
    prop: 'id',
    titleClass: '', // class for title cell
    sortable: true
  },
  {
    title: 'Name',
    prop: 'name',
    component: markRaw(ProgramIcon),
    class: '!py-0',
    titleClass: '!pl-14',
    click: row => (isAdmin() ? router.push(`/program/${row.id}`) : router.push(`/program/seo/${row.id}`)),
    sortable: true
  },
  {
    title: 'Category',
    value: row => row.Category.name
  },
  {
    title: 'Developer',
    value: row => row.Developer.name
  },
  {
    title: 'Publisher',
    value: row => row.Publisher.name
  },
  {
    title: 'Type',
    prop: 'licenseType'
  },
  {
    class: 'no-animation text-right !max-w-max z-10 relative',
    component: markRaw(ProgramListActions)
  }
];

async function onDelete(row) {
  const confirm = await Modal.confirm({
    title: 'Delete Program',
    body: `Delete the program "${row.name}"?`,
    okButton: 'Delete',
    iconColor: 'primary'
  });

  if (!confirm) {
    return;
  }

  const { data } = await useFetch(`${apiDomain}/api/program/${row.id}`).delete().json();

  if (data.value?.success) {
    toast.success('Program deleted successfully');
    reloadGrid();
    return;
  }

  toast.error('Error deleting program');
}

async function onShowProgram(row) {
  const { data } = await useFetch(`${apiDomain}/api/program/show/${row.id}`).get().json();

  if (data.value?.success) {
    toast.success('Program show successfully');
    reloadGrid();
    return;
  }

  toast.error('Error showing program');
}

async function onHideProgram(row) {
  const { data } = await useFetch(`${apiDomain}/api/program/hide/${row.id}`).get().json();

  if (data.value?.success) {
    toast.success('Program hidden successfully');
    reloadGrid();
    return;
  }

  toast.error('Error hidden program');
}

async function onEnable(row) {
  const { data } = await useFetch(`${apiDomain}/api/program/enable/${row.id}`).get().json();

  if (data.value?.success) {
    toast.success('Program enabled successfully');
    reloadGrid();
    return;
  }

  toast.error('Error enabling program');
}

async function onDisable(row) {
  const { data } = await useFetch(`${apiDomain}/api/program/disable/${row.id}`).get().json();

  if (data.value?.success) {
    toast.success('Program disabled successfully');
    reloadGrid();
    return;
  }

  toast.error('Error disabling program');
}
</script>
