<template>
  <page-header title="Stripe Webhooks" />

  <div v-if="isEmpty && search === null">
    Nothing here
  </div>

  <template v-else>
    <div class="flex justify-between mb-4">
      <input-search v-model="search" class="max-w-lg grow" />

      <reload-button class="ml-4" @click="reloadGrid" />
    </div>

    <grid
      ref="gridRef"
      :data-handler="getWebhooks"
      :columns="columns"
      :row-class="rowClass"
      :per-page="15"
    />
  </template>
</template>

<script setup>
import { ref, computed } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import ReloadButton from '../components/ReloadButton.vue';
import InputSearch from '../components/InputSearch.vue';
import Grid from '../components/grid/Grid.vue';
import { useFetch, watchDebounced, useUrlSearchParams } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { apiDomain } from '../composables/useConstants';
import dayjs from 'dayjs';

const gridRef = ref(null);
const isEmpty = ref(false);
const router = useRouter();

function rowClass(_row, index) {
  return (index % 2 ? 'bg-zinc-300/10' : '') + (_row.deletedAt ? ' [&>td:not(:last-child)]:opacity-30' : ''); // stripes class for row
}

function reloadGrid(updateQuery = {}) {
  if (gridRef.value?.reload) {
    gridRef.value.reload(updateQuery);
  }
}

const params = useUrlSearchParams('history', { initialValue: { search: null } });

const search = computed({
  get: () => params.search,
  set: value => params.search = value
});

watchDebounced(
  search,
  () => {
    reloadGrid({ offset: 0 });
  },
  { debounce: 500, maxWait: 1000 }
);

function getUrl({ limit, offset, sort, direction }) {
  const data = { limit, offset, order: sort, direction: direction?.toLowerCase(), search: search.value ?? '' };

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/stripe/?${searchParams}`;
}

async function getWebhooks(args) {
  const { limit, offset, sort, direction } = args;
  const { data } = await useFetch(getUrl({ limit, offset, sort, direction })).get().json();

  isEmpty.value = false;

  return {
    total: data.value.total,
    rows: data.value.items
  };
}

const columns = [
  {
    title: '#',
    prop: 'id',
    titleClass: '',
    class: 'cursor-pointer',
    sortable: true,
    click: row => router.push(`/stripe-webhook/${row.id}`)
  },
  {
    title: 'UserId',
    prop: 'userId',
    class: '!py-0 cursor-pointer',
    titleClass: '!pl-14',
    click: row => (row.userId ? router.push(`/user/${row.userId}`) : null),
    sortable: true
  },
  {
    title: 'Email',
    value: row => row.User?.email,
    class: 'cursor-pointer',
    click: row => (row.userId ? router.push(`/user/${row.userId}`) : null)
  },
  {
    title: 'Date',
    prop: 'createdAt',
    value: row => dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS'),
    click: row => router.push(`/stripe-webhook/${row.id}`),
    sortable: true
  },
  {
    title: 'Event',
    prop: 'eventType',
    click: row => router.push(`/stripe-webhook/${row.id}`),
    sortable: true
  },
  {
    title: 'Details',
    prop: 'details',
    click: row => router.push(`/stripe-webhook/${row.id}`)
  }
];
</script>
