<template>
  <div class="relative inline-block">
    <DashboardModal
      :uppy="uppy"
      :open="open"
      :props="{
        onRequestCloseModal: handleClose,
      }"
    />
    <slot />
    <DragDrop
      :uppy="uppy"
      class="absolute inset-0 overflow-hidden z-10 opacity-0"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import DashboardModal from '@uppy/vue/lib/dashboard-modal';
import DragDrop from '@uppy/vue/lib/drag-drop';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { apiDomain } from '../composables/useConstants';

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([ 'uploaded' ]);

const open = ref(false);
const debug = import.meta.env.DEV && false;

const uppy = new Uppy({ id: 'uppy', autoProceed: false, debug }).use(AwsS3, {
  id: 'myAWSPlugin',
  endpoint: apiDomain + '/api/',
  getTemporarySecurityCredentials: false,
  shouldUseMultipart: true,
  restrictions: {
    maxFileSize: 1000000,
    maxNumberOfFiles: props.multiple ? 30 : 1
  }
});
uppy.on('file-added', () => {
  open.value = true;
});
uppy.on('file-removed', () => {
  if (uppy.getFiles().length === 0) {
    open.value = false;
  }
});
uppy.on('complete', response => {
  if (response.successful.length > 0) {
    emit('uploaded', response.successful.map(obj => obj.uploadURL));
  }

  if (response.failed.length === 0) {
    handleClose();
  }
});

function handleClose() {
  uppy.clear();
  open.value = false;
}
</script>

<style scoped>
:deep(.uppy-Root) {
  width: 100%;
  height: 100%;
}
:deep(.uppy-DragDrop-container) {
  cursor: pointer;
}
</style>
