<template>
  <page-header title="Users">
    <router-link
      to="/user"
      class="btn-primary ml-auto h-10"
    >
      <PlusCircleIcon class="size-5 -ml-1 mr-2" />
      Add
    </router-link>
  </page-header>

  <programs-empty v-if="isEmpty && search === null" />

  <template v-else>
    <div class="flex justify-between mb-4">
      <input-search v-model.trim="search" class="max-w-lg grow" />

      <reload-button class="ml-4" @click="reloadGrid" />
    </div>

    <grid
      ref="gridRef"
      :data-handler="getUsers"
      :columns="columns"
      :row-class="rowClass"
      :per-page="15"
      @enable="onEnable"
      @disable="onDisable"
      @add-time="onAddTime"
    />
  </template>
</template>

<script setup>
import { ref, markRaw, computed } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import ReloadButton from '../components/ReloadButton.vue';
import InputSearch from '../components/InputSearch.vue';
import ProgramsEmpty from '../components/ProgramsEmpty.vue';
import UserListActions from '../components/UserListActions.vue';
import UserIcon from '../components/UserIcon.vue';
import UserCanRunPrograms from '../components/UserCanRunPrograms.vue';
import UserIsDeleted from '../components/UserIsDeleted.vue';
import Grid from '../components/grid/Grid.vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { useFetch, watchDebounced, useUrlSearchParams } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { apiDomain } from '../composables/useConstants';
import { Modal } from '../composables/useModal';
import { useToast } from 'vue-toastification';

const gridRef = ref(null);
const isEmpty = ref(false);
const router = useRouter();
const toast = useToast();

function rowClass(_row, index) {
  if (_row.tier === 'admin') {
    return 'bg-primary-100/50';
  }
  if (_row.tier === 'seo') {
    return 'bg-fuchsia-100/50';
  }
  return (index % 2 ? 'bg-zinc-300/10' : '') + (_row.deletedAt ? ' [&>td:not(:last-child)]:opacity-30' : ''); // stripes class for row
}

function reloadGrid(updateQuery = {}) {
  if (gridRef.value?.reload) {
    gridRef.value.reload(updateQuery);
  }
}

const params = useUrlSearchParams('history', { initialValue: { search: null } });

const search = computed({
  get: () => params.search,
  set: value => params.search = value
});

watchDebounced(
  search,
  () => {
    reloadGrid({ offset: 0 });
  },
  { debounce: 500, maxWait: 1000 }
);

function getUrl({ limit, offset, sort, direction }) {
  const data = { limit, offset, order: sort, direction: direction?.toLowerCase(), search: search.value ?? '' };

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/user/?${searchParams}`;
}

async function getUsers(args) {
  const { limit, offset, sort, direction } = args;
  const { data } = await useFetch(getUrl({ limit, offset, sort, direction })).get().json();

  isEmpty.value = false;

  return {
    total: data.value.total,
    rows: data.value.users
  };
}

const columns = [
  {
    title: '#',
    prop: 'id',
    titleClass: '', // class for title cell
    sortable: true
  },
  {
    title: 'Name',
    prop: 'name',
    component: markRaw(UserIcon),
    class: '!py-0',
    titleClass: '!pl-14',
    click: row => router.push(`/user/${row.id}`),
    sortable: true
  },
  {
    title: 'Email',
    prop: 'email',
    sortable: true
  },
  {
    title: 'Is enabled',
    component: markRaw(UserIsDeleted)
  },
  {
    title: 'Can run programs',
    component: markRaw(UserCanRunPrograms)
  },
  {
    class: 'no-animation text-right !max-w-max z-10 relative',
    component: markRaw(UserListActions)
  }
];

async function onEnable(row) {
  const confirm = await Modal.confirm({
    title: 'Enable User',
    body: `Enable the user "${row.name}"?`,
    okButton: 'Enable',
    iconColor: 'primary'
  });

  if (!confirm) {
    return;
  }

  const { data } = await useFetch(`${apiDomain}/api/user/enable/${row.id}`).get().json();

  if (data.value?.success) {
    toast.success('User enabled successfully');
    reloadGrid();
    return;
  }

  toast.error('Error enabling user');
}

async function onDisable(row) {
  const confirm = await Modal.confirm({
    title: 'Disable User',
    body: `Disable the user "${row.name}"?`,
    okButton: 'Disable',
    iconColor: 'primary'
  });

  if (!confirm) {
    return;
  }

  const { data } = await useFetch(`${apiDomain}/api/user/disable/${row.id}`).get().json();

  if (data.value?.success) {
    toast.success('User disabled successfully');
    reloadGrid();
    return;
  }

  toast.error('Error disabling user');
}

async function onAddTime(row) {
  const { data } = await useFetch(`${apiDomain}/api/user/add-time/${row.id}`).get().json();

  if (data.value?.success) {
    toast.success('User recieved +1 hour');
    reloadGrid();
    return;
  }

  toast.error('Error adding +1 hour user');
}
</script>
